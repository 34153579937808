/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LegendPayload,
  ReferenceLine,
} from "recharts"
import { Row, Col } from "react-flexbox-grid"

import { CalcResult } from "./calculator"
import { formatCurrency, formatMonth } from "./format"
import today from "./today"

type Props = {
  calcRes: CalcResult
}

const formatTooltip = v => formatCurrency(v as number)

const Chart = React.memo((props: Props) => {
  const data = props.calcRes.paymentStatuses
    .filter(ps => !ps.commissionType && !ps.isPrepayment)
    .map(ps => ({
      month: ps.month,
      year: ps.year,
      date: formatMonth(ps.month, ps.year),
      percent: Math.round(ps.percentPayment),
      debt: Math.round(ps.debtPayment),
    }))
  if (data.length === 0) {
    return null
  }
  const balancePoint = data.find(d => d.percent <= d.debt)
  const haveBalancePoint = balancePoint && balancePoint.date !== data[0].date
  const balancePointLegend: LegendPayload = {
    id: "balance",
    value: "Погашение: долг >= процентов",
    type: "line",
    color: "red",
  }
  const isPointInPast = (d: any) =>
    d.year < today.getFullYear() || (d.year === today.getFullYear() && d.month < today.getMonth() + 1)
  const nowPoint = isPointInPast(data[0]) && data.find(d => !isPointInPast(d))
  const nowLegend: LegendPayload = {
    id: "now",
    value: "Сегодня",
    type: "line",
    color: "blue",
  }
  const primaryLegend: LegendPayload[] = [
    { id: "percent", value: "Погашение процентов", type: "rect", color: "#8884d8" },
    { id: "debt", value: "Погашение долга", type: "rect", color: "#82ca9d" },
  ]
  const legend = primaryLegend.concat(haveBalancePoint ? [balancePointLegend] : []).concat(nowPoint ? [nowLegend] : [])

  return (
    <Row sx={{ height: "50vh", maxHeight: `100%`, mt: 3 }}>
      <Col xs={12}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" minTickGap={12} />
            <YAxis />
            <Tooltip formatter={formatTooltip} />
            <Legend payload={legend} />
            {haveBalancePoint && <ReferenceLine x={balancePoint.date} stroke="red" />}
            {nowPoint && <ReferenceLine x={nowPoint.date} stroke="blue" />}
            <Area
              type="monotone"
              dataKey="percent"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
              name="Погашение процентов"
            />
            <Area type="monotone" dataKey="debt" stackId="1" stroke="#82ca9d" fill="#82ca9d" name="Погашение долга" />
          </AreaChart>
        </ResponsiveContainer>
      </Col>
    </Row>
  )
})

export default Chart
